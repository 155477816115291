<template>
  <svg
    width="40"
    height="33"
    viewBox="0 0 40 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_5_199)">
      <path
        d="M11.973 18.1192C11.8746 18.1192 11.7517 18.0947 11.6779 18.0209L3.26982 11.4567C3.04856 11.2846 3.02397 10.9896 3.19607 10.7683C3.36816 10.547 3.66319 10.5224 3.88445 10.6945L12.2926 17.2342C12.5138 17.4063 12.5384 17.7013 12.3663 17.9226C12.268 18.0455 12.1205 18.1192 11.973 18.1192Z"
        fill="inherit"
      />
      <path
        d="M28.027 18.1192C27.8795 18.1192 27.732 18.0455 27.6337 17.9226C27.4616 17.7013 27.5108 17.4063 27.7074 17.2342L36.1401 10.6699C36.3614 10.4978 36.6564 10.547 36.8285 10.7437C37.0006 10.965 36.9514 11.26 36.7548 11.4321L28.3467 17.9717C28.2483 18.0701 28.1254 18.1192 28.027 18.1192Z"
        fill="inherit"
      />
      <path
        d="M31.9607 27.855H8.06393C7.84267 27.855 7.67057 27.732 7.59681 27.5108L2.28643 11.8009C2.18809 11.555 2.3356 11.26 2.60603 11.1862C2.87647 11.0879 3.14691 11.2354 3.22066 11.5058L8.40812 26.8961H31.5919L36.7794 11.5058C36.8777 11.26 37.1481 11.1125 37.394 11.1862C37.6398 11.2846 37.7873 11.555 37.7136 11.8009L32.4278 27.5353C32.354 27.732 32.1574 27.855 31.9607 27.855Z"
        fill="inherit"
      />
      <path
        d="M13.7431 23.0854C11.9238 23.0854 10.4487 21.6103 10.4487 19.791C10.4487 18.783 10.8912 17.8488 11.6779 17.2342C11.8992 17.0621 12.1942 17.0867 12.3663 17.3079C12.5384 17.5292 12.5138 17.8242 12.2926 17.9963C11.7517 18.4388 11.4321 19.0781 11.4321 19.791C11.4321 21.0694 12.4647 22.102 13.7431 22.102C15.0215 22.102 16.0541 21.0694 16.0541 19.791C16.0541 18.5126 15.0215 17.48 13.7431 17.48H13.7185C13.4481 17.5292 13.2022 17.3325 13.1776 17.0621C13.1284 16.7916 13.3251 16.5458 13.5956 16.4966C13.6447 16.4966 13.6939 16.4966 13.7431 16.4966C15.5624 16.4966 17.0375 17.9717 17.0375 19.791C17.0129 21.6103 15.5378 23.0854 13.7431 23.0854Z"
        fill="inherit"
      />
      <path
        d="M13.6447 17.48C13.571 17.48 13.5218 17.4554 13.4481 17.4309C13.2022 17.3325 13.0793 17.0375 13.2022 16.7916L18.8814 3.58942C18.9797 3.34357 19.2747 3.22065 19.5206 3.34357C19.7664 3.44191 19.8894 3.73694 19.7664 3.98279L14.0873 17.185C14.0135 17.3817 13.8414 17.48 13.6447 17.48Z"
        fill="inherit"
      />
      <path
        d="M26.3552 17.48C26.1586 17.48 25.9865 17.3571 25.9127 17.185L20.2335 3.98281C20.1352 3.73695 20.2335 3.44193 20.4794 3.34359C20.7252 3.24525 21.0203 3.34359 21.1186 3.58944L26.7978 16.7917C26.8961 17.0375 26.7978 17.3325 26.5519 17.4309C26.5028 17.48 26.429 17.48 26.3552 17.48Z"
        fill="inherit"
      />
      <path
        d="M26.2815 23.0854C24.4622 23.0854 22.9871 21.6103 22.9871 19.791C22.9871 17.9717 24.4622 16.4966 26.2815 16.4966C26.3307 16.4966 26.3798 16.4966 26.429 16.4966C26.6994 16.5458 26.8961 16.7916 26.847 17.0621C26.7978 17.3325 26.5519 17.5292 26.2815 17.48H26.2569C24.9785 17.48 23.9459 18.5126 23.9459 19.791C23.9459 21.0694 24.9785 22.102 26.2569 22.102C27.5353 22.102 28.5679 21.0694 28.5679 19.791C28.5679 19.0781 28.2483 18.4388 27.7074 17.9963C27.4862 17.8242 27.4616 17.5292 27.6337 17.3079C27.8058 17.0867 28.1254 17.0621 28.3221 17.2342C29.1088 17.8488 29.5513 18.783 29.5513 19.791C29.5513 21.6103 28.0762 23.0854 26.2815 23.0854Z"
        fill="inherit"
      />
      <path
        d="M20.0123 4.42532C18.783 4.42532 17.7996 3.44192 17.7996 2.21266C17.7996 0.983405 18.783 0 20.0123 0C21.2415 0 22.2249 0.983405 22.2249 2.21266C22.2249 3.44192 21.217 4.42532 20.0123 4.42532ZM20.0123 0.983405C19.3239 0.983405 18.783 1.52428 18.783 2.21266C18.783 2.90104 19.3239 3.44192 20.0123 3.44192C20.7007 3.44192 21.2415 2.90104 21.2415 2.21266C21.2415 1.52428 20.6761 0.983405 20.0123 0.983405Z"
        fill="inherit"
      />
      <path
        d="M37.7873 12.2188C36.5581 12.2188 35.5747 11.2354 35.5747 10.0061C35.5747 8.77689 36.5581 7.79349 37.7873 7.79349C39.0166 7.79349 40 8.77689 40 10.0061C40 11.2354 39.0166 12.2188 37.7873 12.2188ZM37.7873 8.77689C37.099 8.77689 36.5581 9.31777 36.5581 10.0061C36.5581 10.6945 37.099 11.2354 37.7873 11.2354C38.4757 11.2354 39.0166 10.6945 39.0166 10.0061C39.0166 9.31777 38.4757 8.77689 37.7873 8.77689Z"
        fill="inherit"
      />
      <path
        d="M2.21266 12.2188C0.983405 12.2188 0 11.2354 0 10.0061C0 8.77689 0.983405 7.79349 2.21266 7.79349C3.44192 7.79349 4.42532 8.77689 4.42532 10.0061C4.42532 11.2354 3.44192 12.2188 2.21266 12.2188ZM2.21266 8.77689C1.52428 8.77689 0.983405 9.31777 0.983405 10.0061C0.983405 10.6945 1.52428 11.2354 2.21266 11.2354C2.90104 11.2354 3.44192 10.6945 3.44192 10.0061C3.44192 9.31777 2.90104 8.77689 2.21266 8.77689Z"
        fill="inherit"
      />
      <path
        d="M32.5753 32.354H7.40013C6.58882 32.354 5.92502 31.6902 5.92502 30.8789V28.3221C5.92502 27.5108 6.58882 26.847 7.40013 26.847H32.5753C33.3866 26.847 34.0504 27.5108 34.0504 28.3221V30.8789C34.0504 31.6902 33.3866 32.354 32.5753 32.354ZM7.40013 27.8549C7.12969 27.8549 6.90842 28.0762 6.90842 28.3466V30.9035C6.90842 31.1739 7.12969 31.3952 7.40013 31.3952H32.5753C32.8457 31.3952 33.067 31.1739 33.067 30.9035V28.3466C33.067 28.0762 32.8457 27.8549 32.5753 27.8549H7.40013Z"
        fill="inherit"
      />
      <path
        d="M6.88383 14.1364C6.78549 14.1364 6.68715 14.1119 6.58881 14.0381C6.36754 13.8906 6.31837 13.571 6.46588 13.3497C8.70313 10.252 12.2926 8.40811 16.1033 8.40811H17.1112C17.3817 8.40811 17.6029 8.62938 17.6029 8.89981C17.6029 9.17025 17.3817 9.39152 17.1112 9.39152H16.1033C12.6122 9.39152 9.29317 11.0879 7.2526 13.9152C7.17885 14.0627 7.03134 14.1364 6.88383 14.1364Z"
        fill="inherit"
      />
      <path
        d="M33.1653 14.1365C33.0178 14.1365 32.8703 14.0627 32.772 13.9398C30.7314 11.1125 27.4124 9.41611 23.9213 9.41611H22.9133C22.6429 9.41611 22.4216 9.19485 22.4216 8.92441C22.4216 8.65397 22.6429 8.43271 22.9133 8.43271H23.9213C27.732 8.43271 31.346 10.2766 33.5587 13.3743C33.7062 13.5956 33.657 13.8906 33.4358 14.0627C33.362 14.1119 33.2637 14.1365 33.1653 14.1365Z"
        fill="inherit"
      />
    </g>
    <defs>
      <clipPath id="clip0_5_199">
        <rect width="40" height="32.354" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'TopGamesIcon',
}
</script>
