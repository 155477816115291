<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1_171)">
      <g clip-path="url(#clip1_1_171)">
        <path
          d="M28.4721 9.73208C28.0377 9.73208 27.6321 9.61623 27.2266 9.41347L25.0833 8.25489C23.9537 7.64664 23.4323 6.34324 23.8378 5.12673L25.1412 1.24548C25.2281 0.955835 25.5757 0.782048 25.8653 0.868942C26.155 0.9848 26.3287 1.30341 26.2419 1.59305L24.9384 5.50326C24.7067 6.16945 24.9964 6.92253 25.6336 7.24114L27.777 8.39972C28.4142 8.74729 29.1673 8.5735 29.6017 8.02318L32.0927 4.83708C32.2954 4.5764 32.643 4.54744 32.9037 4.75019C33.1644 4.95294 33.1933 5.30051 32.9906 5.56119L30.4996 8.74729C30.0362 9.38451 29.2542 9.73208 28.4721 9.73208Z"
          fill="inherit"
        />
        <path
          d="M11.5568 9.76107C10.8038 9.76107 10.0507 9.41349 9.52933 8.80524L6.86459 5.70603C6.66184 5.47432 6.6908 5.09778 6.92252 4.89503C7.15424 4.69228 7.53077 4.72124 7.73353 4.95296L10.4272 8.05216C10.8907 8.63145 11.6727 8.77627 12.281 8.4287L14.3954 7.24115C15.0036 6.89358 15.2933 6.16947 15.0615 5.47432L13.7292 1.651C13.6133 1.36136 13.7871 1.01378 14.0768 0.897924C14.3664 0.782066 14.714 0.955853 14.8298 1.2455L16.1912 5.06881C16.6256 6.28532 16.1043 7.58873 15.0036 8.22595L12.8892 9.41349C12.4547 9.64521 11.9913 9.76107 11.5568 9.76107Z"
          fill="inherit"
        />
        <path
          d="M0.955826 24.2433C0.666181 24.2433 0.434465 24.0406 0.376536 23.7509C0.318607 23.4323 0.550323 23.1427 0.839968 23.0847L4.895 22.4186C5.59015 22.3027 6.11151 21.6944 6.11151 20.9993V18.5663C6.11151 17.8422 5.61911 17.2629 4.92396 17.118L0.926862 16.3939C0.608252 16.336 0.4055 16.0464 0.463429 15.7278C0.521358 15.4091 0.811004 15.2064 1.12961 15.2643L5.12672 15.9595C6.37219 16.1912 7.29906 17.2629 7.27009 18.5373V20.9703C7.27009 22.2448 6.34323 23.3165 5.09775 23.5192L1.04272 24.1854C1.01376 24.2433 0.984791 24.2433 0.955826 24.2433Z"
          fill="inherit"
        />
        <path
          d="M13.7292 38.9573C13.6713 38.9573 13.5844 38.9573 13.5264 38.9283C13.2368 38.8125 13.063 38.4938 13.1789 38.1752L14.5981 34.323C14.8588 33.6568 14.5692 32.9037 13.9609 32.5561L11.8465 31.3396C11.2382 30.992 10.4562 31.1079 9.99276 31.6582L7.41492 34.7574C7.21217 34.9891 6.83563 35.0471 6.60392 34.8443C6.3722 34.6416 6.31427 34.265 6.51702 34.0333L9.09486 30.9341C9.90587 29.9493 11.2962 29.7176 12.4258 30.3548L14.5402 31.5713C15.6408 32.2085 16.1332 33.5409 15.6698 34.7285L14.2505 38.5807C14.1926 38.8125 13.9609 38.9573 13.7292 38.9573Z"
          fill="inherit"
        />
        <path
          d="M26.0681 39.0442C25.8363 39.0442 25.6046 38.8994 25.5177 38.6676L24.0985 34.8733C23.664 33.6857 24.1274 32.3534 25.2281 31.7162L27.3425 30.4997C28.4432 29.8624 29.8335 30.0941 30.6734 31.0789L33.3092 34.2361C33.5119 34.4678 33.483 34.8443 33.2513 35.0471C33.0195 35.2498 32.643 35.2209 32.4403 34.9892L29.8045 31.832C29.3411 31.2817 28.559 31.1658 27.9508 31.5134L25.8363 32.7299C25.2281 33.0775 24.9674 33.8306 25.1991 34.4968L26.6184 38.2911C26.7342 38.5807 26.5894 38.9283 26.2708 39.0442C26.1839 39.0152 26.126 39.0442 26.0681 39.0442Z"
          fill="inherit"
        />
        <path
          d="M39.0442 24.475C39.0152 24.475 38.9862 24.475 38.9573 24.475L34.9602 23.8089C33.7147 23.6061 32.7878 22.5344 32.7589 21.26L32.7299 18.827C32.7299 17.5525 33.6278 16.4519 34.8733 16.2202L38.8993 15.496C39.218 15.4381 39.5076 15.6409 39.5655 15.9595C39.6235 16.2781 39.4207 16.5677 39.1021 16.6257L35.076 17.3787C34.3809 17.4946 33.8885 18.1028 33.8885 18.827L33.9174 21.26C33.9174 21.9841 34.4388 22.5634 35.134 22.6792L39.1311 23.3454C39.4497 23.4034 39.6524 23.693 39.6235 24.0116C39.5655 24.2723 39.3049 24.475 39.0442 24.475Z"
          fill="inherit"
        />
        <path
          d="M20.7965 4.08401C20.7965 4.51848 20.4489 4.86606 20.0145 4.86606C19.58 4.86606 19.2324 4.51848 19.2324 4.08401C19.2324 3.64955 19.58 3.30197 20.0145 3.30197C20.4489 3.30197 20.7965 3.64955 20.7965 4.08401Z"
          fill="inherit"
        />
        <path
          d="M20.0145 5.47431C19.2614 5.47431 18.6532 4.86606 18.6532 4.11298C18.6532 3.3599 19.2614 2.75165 20.0145 2.75165C20.7676 2.75165 21.3758 3.3599 21.3758 4.11298C21.3758 4.86606 20.7676 5.47431 20.0145 5.47431ZM20.0145 3.88126C19.8986 3.88126 19.8117 3.96816 19.8117 4.08402C19.8117 4.19987 19.8986 4.28677 20.0145 4.28677C20.1303 4.28677 20.2172 4.19987 20.2172 4.08402C20.2172 3.96816 20.1303 3.88126 20.0145 3.88126Z"
          fill="inherit"
        />
        <path
          d="M34.2361 12.8313C33.8595 13.0341 33.3671 12.9182 33.1644 12.5417C32.9616 12.1651 33.0775 11.6727 33.454 11.47C33.8306 11.2672 34.323 11.3831 34.5257 11.7596C34.7285 12.1362 34.6126 12.5996 34.2361 12.8313Z"
          fill="inherit"
        />
        <path
          d="M33.8306 13.4975C33.7147 13.4975 33.5988 13.4685 33.454 13.4396C33.1064 13.3527 32.8168 13.1209 32.614 12.8023C32.4403 12.4837 32.3823 12.1072 32.4982 11.7596C32.5851 11.412 32.8168 11.1224 33.1354 10.9196C33.8016 10.5431 34.6416 10.7748 34.9891 11.441C35.1629 11.7596 35.2209 12.1361 35.105 12.4837C34.9891 12.8313 34.7864 13.1209 34.4678 13.3237C34.294 13.4396 34.0623 13.4975 33.8306 13.4975ZM33.8306 11.9334C33.8016 11.9334 33.7726 11.9334 33.7147 11.9624C33.6568 11.9913 33.6278 12.0493 33.6278 12.0782C33.6278 12.1072 33.5988 12.1651 33.6568 12.223C33.6857 12.281 33.7437 12.3099 33.7726 12.3099C33.8016 12.3099 33.8595 12.3389 33.9174 12.281C33.9754 12.252 34.0043 12.1941 34.0043 12.1651C34.0043 12.1361 34.0333 12.0782 33.9754 12.0203C33.9754 11.9624 33.9175 11.9334 33.8306 11.9334Z"
          fill="inherit"
        />
        <path
          d="M33.3671 28.6749C32.9906 28.4432 32.8747 27.9797 33.0775 27.6032C33.3092 27.2267 33.7726 27.1108 34.1492 27.3136C34.5257 27.5453 34.6415 28.0087 34.4388 28.3852C34.2071 28.7908 33.7436 28.9066 33.3671 28.6749Z"
          fill="inherit"
        />
        <path
          d="M33.7437 29.37C33.5119 29.37 33.2802 29.3121 33.0485 29.1962C32.4113 28.8197 32.1796 27.9797 32.5561 27.3136C32.9326 26.6763 33.7726 26.4446 34.4388 26.8212C35.076 27.1977 35.3077 28.0377 34.9312 28.7038C34.6705 29.1383 34.2071 29.37 33.7437 29.37ZM33.6568 28.1825C33.7437 28.2404 33.8885 28.2115 33.9464 28.0956C34.0043 28.0087 33.9754 27.8639 33.8595 27.8059C33.7726 27.748 33.6278 27.777 33.5699 27.8928C33.5119 28.0087 33.5409 28.1246 33.6568 28.1825Z"
          fill="inherit"
        />
        <path
          d="M19.2324 35.916C19.2324 35.4815 19.58 35.134 20.0145 35.134C20.4489 35.134 20.7965 35.4815 20.7965 35.916C20.7965 36.3505 20.4489 36.6981 20.0145 36.6981C19.58 36.6981 19.2324 36.3505 19.2324 35.916Z"
          fill="inherit"
        />
        <path
          d="M20.0145 37.2774C19.2614 37.2774 18.6532 36.6691 18.6532 35.916C18.6532 35.1629 19.2614 34.5547 20.0145 34.5547C20.7676 34.5547 21.3758 35.1629 21.3758 35.916C21.3758 36.6691 20.7676 37.2774 20.0145 37.2774ZM20.0145 35.6843C19.8986 35.6843 19.8117 35.7712 19.8117 35.8871C19.8117 36.0029 19.8986 36.0898 20.0145 36.0898C20.1303 36.0898 20.2172 36.0029 20.2172 35.8871C20.2172 35.7712 20.1303 35.6843 20.0145 35.6843Z"
          fill="inherit"
        />
        <path
          d="M5.90877 27.3714C6.28531 27.1397 6.77771 27.2846 6.98046 27.6611C7.21218 28.0376 7.06735 28.53 6.69082 28.7328C6.31428 28.9645 5.82188 28.8197 5.61913 28.4431C5.38741 28.0956 5.53223 27.6032 5.90877 27.3714Z"
          fill="inherit"
        />
        <path
          d="M6.31427 29.4279C6.19841 29.4279 6.08256 29.399 5.9667 29.37C5.61912 29.2831 5.32948 29.0514 5.12673 28.7328C4.75019 28.0956 4.95294 27.2556 5.61912 26.8501C6.25634 26.4736 7.09631 26.6763 7.50182 27.3425C7.87836 27.9797 7.67561 28.8197 7.00942 29.2252C6.7777 29.37 6.54599 29.4279 6.31427 29.4279ZM6.19841 27.8928C6.11152 27.9508 6.05359 28.0666 6.11152 28.1825C6.16945 28.2694 6.28531 28.3273 6.40117 28.2694C6.48806 28.2114 6.54599 28.0956 6.48806 27.9797C6.43013 27.8639 6.28531 27.8349 6.19841 27.8928Z"
          fill="inherit"
        />
        <path
          d="M19.9855 40C18.0449 40 16.1043 39.7104 14.1636 39.1311C9.0659 37.567 4.86604 34.1202 2.34613 29.399C-0.173786 24.6778 -0.666183 19.2614 0.868936 14.1637C4.11296 3.62057 15.2933 -2.34612 25.8653 0.868944C30.9631 2.43303 35.1629 5.87981 37.6828 10.601C40.2028 15.3222 40.7241 20.7386 39.16 25.8364C36.4953 34.4388 28.559 40 19.9855 40ZM20.0145 1.15859C11.9623 1.15859 4.46054 6.40117 1.99855 14.4823C0.521362 19.2904 1.01376 24.3881 3.38885 28.8487C5.76394 33.2802 9.70311 36.5532 14.5112 38.0304C24.4461 41.0717 34.9891 35.4526 38.0304 25.5177C39.5076 20.7096 39.0152 15.6119 36.6401 11.1513C34.265 6.71978 30.3258 3.44679 25.5177 1.9696C23.693 1.41927 21.8392 1.15859 20.0145 1.15859Z"
          fill="inherit"
        />
        <path
          d="M6.54598 11.4989C6.92252 11.7017 7.06734 12.1941 6.83562 12.5706C6.63287 12.9472 6.14048 13.092 5.76394 12.8603C5.3874 12.6575 5.24258 12.1651 5.47429 11.7886C5.67704 11.412 6.16944 11.2962 6.54598 11.4989Z"
          fill="inherit"
        />
        <path
          d="M6.14048 13.5554C5.90876 13.5554 5.67705 13.4975 5.47429 13.3816C5.15569 13.2078 4.92397 12.9182 4.83708 12.5706C4.75018 12.223 4.77915 11.8465 4.95293 11.5279C5.12672 11.2093 5.41637 10.9776 5.76394 10.8907C6.11151 10.8038 6.48805 10.8328 6.80666 11.0065C7.12527 11.1803 7.35699 11.47 7.44388 11.8175C7.53078 12.1651 7.50181 12.5417 7.32802 12.8603C7.15424 13.1789 6.86459 13.4106 6.51702 13.4975C6.40116 13.5265 6.2853 13.5554 6.14048 13.5554ZM6.14048 11.9913C6.11151 11.9913 6.11151 11.9913 6.08255 11.9913C6.05359 11.9913 5.99566 12.0203 5.96669 12.0782C5.93773 12.1362 5.93773 12.1941 5.93773 12.223C5.93773 12.252 5.96669 12.3099 6.02462 12.3389C6.08255 12.3679 6.14048 12.3679 6.16944 12.3679C6.19841 12.3679 6.25634 12.3389 6.2853 12.281C6.31427 12.223 6.31427 12.1651 6.31427 12.1362C6.31427 12.1072 6.2853 12.0493 6.22737 12.0203C6.22737 11.9913 6.19841 11.9913 6.14048 11.9913Z"
          fill="inherit"
        />
        <path
          d="M18.5952 32.0058C18.2766 32.0058 18.0159 31.7451 18.0159 31.4265V28.8487C18.0159 28.5301 18.2766 28.2694 18.5952 28.2694C18.9138 28.2694 19.1745 28.5301 19.1745 28.8487V31.4265C19.1745 31.7451 18.9138 32.0058 18.5952 32.0058Z"
          fill="inherit"
        />
        <path
          d="M20.8255 32.0058C20.5069 32.0058 20.2462 31.7451 20.2462 31.4265V28.8487C20.2462 28.5301 20.5069 28.2694 20.8255 28.2694C21.1441 28.2694 21.4048 28.5301 21.4048 28.8487V31.4265C21.4048 31.7451 21.1441 32.0058 20.8255 32.0058Z"
          fill="inherit"
        />
        <path
          d="M31.4265 21.9841H28.8487C28.53 21.9841 28.2694 21.7234 28.2694 21.4048C28.2694 21.0862 28.53 20.8255 28.8487 20.8255H31.4265C31.7451 20.8255 32.0058 21.0862 32.0058 21.4048C32.0058 21.7234 31.7451 21.9841 31.4265 21.9841Z"
          fill="inherit"
        />
        <path
          d="M31.4265 19.7538H28.8487C28.53 19.7538 28.2694 19.4931 28.2694 19.1745C28.2694 18.8559 28.53 18.5952 28.8487 18.5952H31.4265C31.7451 18.5952 32.0058 18.8559 32.0058 19.1745C32.0058 19.4931 31.7451 19.7538 31.4265 19.7538Z"
          fill="inherit"
        />
        <path
          d="M21.4048 11.7306C21.0862 11.7306 20.8255 11.4699 20.8255 11.1513V8.57349C20.8255 8.25488 21.0862 7.9942 21.4048 7.9942C21.7234 7.9942 21.9841 8.25488 21.9841 8.57349V11.1513C21.9841 11.4699 21.7234 11.7306 21.4048 11.7306Z"
          fill="inherit"
        />
        <path
          d="M19.2035 11.7306C18.8849 11.7306 18.6242 11.4699 18.6242 11.1513V8.57349C18.6242 8.25488 18.8849 7.9942 19.2035 7.9942C19.5221 7.9942 19.7828 8.25488 19.7828 8.57349V11.1513C19.7828 11.4699 19.5221 11.7306 19.2035 11.7306Z"
          fill="inherit"
        />
        <path
          d="M20.0434 32.0637C18.798 32.0637 17.5525 31.861 16.336 31.4844C13.2658 30.4997 10.7458 28.3852 9.26865 25.5178C7.79146 22.6503 7.53078 19.3773 8.51557 16.307C9.50037 13.2368 11.6437 10.7459 14.4823 9.26867C20.391 6.22739 27.6901 8.57352 30.7314 14.4823C33.7726 20.391 31.4265 27.6901 25.5177 30.7314C23.8088 31.6003 21.9261 32.0637 20.0434 32.0637ZM19.9855 9.09488C18.3056 9.09488 16.5967 9.47142 15.0326 10.3114C12.4547 11.6438 10.5431 13.903 9.64519 16.6836C8.74729 19.4642 9.00797 22.4186 10.3114 24.9964C11.6437 27.5742 13.903 29.4859 16.6836 30.3838C19.4642 31.2817 22.4185 31.021 24.9964 29.7176C30.3548 26.966 32.4692 20.391 29.7176 15.0326C27.777 11.2672 23.9537 9.09488 19.9855 9.09488Z"
          fill="inherit"
        />
        <path
          d="M11.1513 19.1745H8.57349C8.25488 19.1745 7.9942 18.9138 7.9942 18.5952C7.9942 18.2766 8.25488 18.0159 8.57349 18.0159H11.1513C11.4699 18.0159 11.7306 18.2766 11.7306 18.5952C11.7306 18.9138 11.4699 19.1745 11.1513 19.1745Z"
          fill="inherit"
        />
        <path
          d="M11.1513 21.4048H8.57349C8.25488 21.4048 7.9942 21.1441 7.9942 20.8255C7.9942 20.5069 8.25488 20.2462 8.57349 20.2462H11.1513C11.4699 20.2462 11.7306 20.5069 11.7306 20.8255C11.7306 21.1441 11.4699 21.4048 11.1513 21.4048Z"
          fill="inherit"
        />
        <path
          d="M16.7415 16.0174C16.9153 15.8146 17.0022 15.554 16.9153 15.2933C16.8574 15.1485 16.8284 15.0036 16.7705 14.8588C16.6836 14.5981 16.6546 14.4533 16.6546 14.3954C16.6546 14.3374 16.6836 14.2795 16.7125 14.2216C16.7705 14.1637 16.8284 14.1347 16.9732 14.1057C17.0891 14.0768 17.5235 14.0768 18.2766 14.0478V13.4106C17.0891 13.4685 16.2491 13.4975 15.7567 13.4975C15.2353 13.4975 14.4243 13.4685 13.3237 13.4106V13.9899C13.874 14.0188 14.1926 14.0768 14.3374 14.1347C14.4243 14.1637 14.5112 14.2216 14.5692 14.3085C14.6561 14.4243 14.8588 14.9167 15.1774 15.7277L15.6119 16.7994C15.6988 17.0311 16.0463 17.0601 16.1912 16.8284C16.307 16.5388 16.5098 16.2491 16.7415 16.0174Z"
          fill="inherit"
        />
        <path
          d="M24.3302 13.4396C23.8668 13.4396 23.1716 13.4106 22.2448 13.3816V13.9609C23.0558 14.0188 23.5192 14.0768 23.664 14.1057C23.8088 14.1637 23.8957 14.2506 23.8957 14.3954C23.8957 14.5112 23.8378 14.7719 23.693 15.2064C23.6061 15.496 23.4902 15.8146 23.4033 16.0753C23.3454 16.2781 23.3744 16.5098 23.4902 16.6836C23.5482 16.7415 23.5771 16.8284 23.6061 16.9153C23.7219 17.118 23.9826 17.0891 24.0695 16.8863L24.5329 15.7857C24.9384 14.8878 25.1412 14.3954 25.1991 14.3085C25.286 14.1926 25.3729 14.1057 25.4598 14.0478C25.5467 14.0188 25.8364 13.9609 26.2998 13.9609V13.3816C25.4888 13.4106 24.8226 13.4396 24.3302 13.4396Z"
          fill="inherit"
        />
        <path
          d="M20.5069 23.6061L20.3331 24.0406L20.1303 23.5482C20.0724 23.4323 19.9855 23.3744 19.8407 23.3744C19.3773 23.3744 18.9138 23.2875 18.5083 23.1716C18.3345 23.1137 18.1897 23.2875 18.2477 23.4323C18.8269 24.9385 19.2035 25.9812 19.3773 26.5025H20.3041C20.42 26.155 20.5069 25.8364 20.5938 25.5757C20.6807 25.315 20.7965 24.9964 20.9414 24.6488L21.4338 23.3454C21.4917 23.2006 21.3469 23.0558 21.202 23.1137C21.0862 23.1427 20.9703 23.1716 20.8545 23.2296C20.7386 23.2585 20.5648 23.4033 20.5069 23.6061Z"
          fill="inherit"
        />
        <path
          d="M16.0753 19.0297C16.0753 16.7994 17.7553 15.2643 19.8986 15.2643C21.0572 15.2064 22.1289 15.7567 22.7951 16.6836C22.9109 16.8574 22.853 17.1181 22.6503 17.2049L22.013 17.5236C21.8393 17.6105 21.6075 17.5525 21.4917 17.4077C21.1151 16.9153 20.5359 16.6257 19.8986 16.6257C18.6242 16.6257 17.6684 17.6394 17.6684 19.0297C17.6684 20.42 18.6242 21.4338 19.8986 21.4338C20.5359 21.4338 21.1441 21.1441 21.4917 20.6228C21.6075 20.4779 21.7813 20.42 21.9551 20.5069L22.7372 20.8834C22.882 20.9414 22.9109 21.1152 22.853 21.26C22.2158 22.2448 21.0862 22.853 19.8986 22.7951C17.7553 22.7951 16.0753 21.26 16.0753 19.0297Z"
          fill="inherit"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1_171">
        <rect width="39.7614" height="40" fill="white" />
      </clipPath>
      <clipPath id="clip1_1_171">
        <rect width="40.029" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
  <!-- #B79B6E -->
</template>
<script>
export default {
  name: 'FivePlayNowIcon',
}
</script>
