<template>
  <svg
    width="40"
    height="33"
    viewBox="0 0 40 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_5_177)">
      <path
        d="M19.9867 28.6742C19.96 28.6742 19.96 28.6742 19.9334 28.6742C19.8268 28.6742 19.6935 28.6209 19.6136 28.5143L0.159896 8.10127C-0.0266466 7.88807 -0.0266466 7.56829 0.159896 7.3551L6.90207 0.159893C7.00866 0.0532978 7.14191 0 7.27515 0H32.6982C32.8581 0 32.9913 0.0532978 33.0979 0.159893L39.8668 7.32845C40.0533 7.54164 40.0533 7.86143 39.8668 8.07462L20.4131 28.4877L20.3864 28.5143C20.3065 28.5943 20.1732 28.6476 20.0666 28.6476C20.0133 28.6742 20.0133 28.6742 19.9867 28.6742ZM13.9374 10.8461L20.1999 27.1552L38.7209 7.70153L32.4584 1.06596H7.51499L1.27915 7.70153L18.5476 25.8228L12.8981 11.1392C12.8714 11.0859 12.8714 11.006 12.8714 10.926L12.978 7.70153C12.978 7.40839 13.2179 7.16855 13.5376 7.1952C13.8308 7.1952 14.0706 7.46169 14.044 7.75483L13.9374 10.8461Z"
        fill="inherit"
      />
      <path
        d="M19.9867 28.6742C19.9334 28.6742 19.8801 28.6742 19.8001 28.6476C19.5336 28.541 19.3738 28.2478 19.4803 27.9813L25.4497 10.8994L25.3698 7.72818C25.3698 7.43504 25.5829 7.1952 25.8761 7.16855C26.1692 7.16855 26.4091 7.38174 26.4357 7.67488L26.5423 10.926C26.5423 10.9793 26.5423 11.0593 26.5157 11.1126L20.493 28.3011C20.4131 28.5143 20.2265 28.6742 19.9867 28.6742Z"
        fill="inherit"
      />
      <path
        d="M39.467 8.2345H0.532978C0.23984 8.2345 0 7.99466 0 7.70153C0 7.40839 0.23984 7.16855 0.532978 7.16855H39.467C39.7602 7.16855 40 7.40839 40 7.70153C40 7.99466 39.7602 8.2345 39.467 8.2345Z"
        fill="inherit"
      />
      <path
        d="M25.956 8.2345C25.956 8.2345 25.9294 8.2345 25.956 8.2345C25.7961 8.2345 25.6362 8.15455 25.5563 8.04796L19.9867 1.35908L13.8841 8.07461C13.7775 8.1812 13.6442 8.26115 13.4843 8.26115C13.3245 8.26115 13.1912 8.1812 13.0846 8.07461L6.87542 0.879402C6.68888 0.666211 6.71552 0.319775 6.92872 0.133233C7.14191 -0.0533095 7.48834 -0.0266606 7.67489 0.186531L13.4843 6.90205L19.5869 0.186531C19.6935 0.079935 19.8534 -1.17326e-05 19.9867 -1.17326e-05C20.1466 -1.17326e-05 20.2798 0.079935 20.3864 0.186531L25.956 6.90205L32.2985 0.159882C32.5117 -0.0533095 32.8314 -0.0533095 33.0446 0.133233C33.2578 0.346424 33.2578 0.666211 33.0713 0.879402L26.3291 8.07461C26.2492 8.1812 26.0893 8.2345 25.956 8.2345Z"
        fill="inherit"
      />
      <path
        d="M31.046 11.4857H9.56694C9.27381 11.4857 9.03397 11.2458 9.03397 10.9527C9.03397 10.6595 9.27381 10.4197 9.56694 10.4197H31.0726C31.3657 10.4197 31.6056 10.6595 31.6056 10.9527C31.6056 11.2458 31.3391 11.4857 31.046 11.4857Z"
        fill="inherit"
      />
      <path
        d="M19.9867 32.2452C19.9067 32.2452 19.8001 32.2185 19.7202 32.1652L10.2598 26.7555C9.99333 26.6223 9.91339 26.2758 10.0733 26.036C10.2065 25.7695 10.553 25.6895 10.7928 25.8494L20.2532 31.2592C20.5197 31.3924 20.5996 31.7388 20.4397 31.9787C20.3598 32.1386 20.1732 32.2452 19.9867 32.2452Z"
        fill="inherit"
      />
      <path
        d="M19.9867 32.2452H4.07729C3.78415 32.2452 3.54431 32.0053 3.54431 31.7122C3.54431 31.4191 3.78415 31.1792 4.07729 31.1792H19.9867C20.2798 31.1792 20.5197 31.4191 20.5197 31.7122C20.5197 32.0053 20.2798 32.2452 19.9867 32.2452Z"
        fill="inherit"
      />
      <path
        d="M19.9867 32.2452C19.8001 32.2452 19.6136 32.1386 19.5336 31.9787C19.4004 31.7122 19.4803 31.3924 19.7202 31.2592L29.1805 25.8494C29.447 25.7162 29.7668 25.7961 29.9001 26.036C30.0333 26.3025 29.9534 26.6223 29.7135 26.7555L20.2532 32.1652C20.1732 32.2185 20.0933 32.2452 19.9867 32.2452Z"
        fill="inherit"
      />
      <path
        d="M35.9227 32.2452H19.9867C19.6935 32.2452 19.4537 32.0053 19.4537 31.7122C19.4537 31.4191 19.6935 31.1792 19.9867 31.1792H35.8961C36.1892 31.1792 36.4291 31.4191 36.4291 31.7122C36.4291 32.0053 36.2159 32.2452 35.9227 32.2452Z"
        fill="inherit"
      />
    </g>
    <defs>
      <clipPath id="clip0_5_177">
        <rect width="40" height="32.2452" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'FiveNewGamesIcon',
}
</script>
