<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1_259)">
      <path
        d="M16.1304 30.3125C15.8469 30.3125 15.5918 30.0857 15.5634 29.7739C15.5351 29.4621 15.7902 29.1786 16.1021 29.1786C18.9653 28.9802 21.6584 27.9596 23.8696 26.1736C24.4082 25.7484 24.9185 25.2665 25.3721 24.7845C29.3125 20.5889 30.2764 14.5223 27.8668 9.30616C27.1014 7.66194 26.0524 6.21615 24.7484 4.96881C23.3877 3.69312 21.8001 2.70092 20.0709 2.07725C19.7874 1.96385 19.6173 1.65202 19.7307 1.34018C19.8441 1.05669 20.1559 0.886602 20.4678 0.999996C22.3104 1.73706 24.0113 2.78596 25.5138 4.17505C26.9313 5.50744 28.0652 7.09496 28.8873 8.85258C31.4954 14.494 30.4465 21.0709 26.1942 25.5783C25.7123 26.0886 25.1736 26.5989 24.5783 27.0808C22.1687 29.0085 19.2771 30.1141 16.1871 30.3125C16.1588 30.3125 16.1588 30.3125 16.1304 30.3125Z"
        fill="inherit"
      />
      <path
        d="M5.15944 26.4288C5.0177 26.4288 4.87595 26.3721 4.76256 26.287C3.96879 25.55 3.26008 24.6995 2.63641 23.8207C1.44576 22.0914 0.623649 20.1354 0.226767 18.0092C-0.0567194 16.5351 -0.113417 15.0326 0.0566754 13.5301C0.113373 12.9348 0.226767 12.3395 0.368511 11.7441C0.992182 9.16441 2.26787 6.78312 4.08219 4.85541C4.50742 4.40183 4.961 3.9766 5.44293 3.57972C5.66972 3.38128 6.03825 3.40963 6.23669 3.66477C6.43513 3.89156 6.40678 4.26009 6.15164 4.45853C5.69806 4.82706 5.30118 5.22394 4.9043 5.64917C3.23173 7.40679 2.06943 9.61799 1.50246 11.9993C1.38906 12.5379 1.27567 13.0765 1.21897 13.6435C1.07723 15.0326 1.13393 16.4217 1.36071 17.7824C1.72925 19.7385 2.46631 21.5244 3.57191 23.1403C4.13889 23.9624 4.79091 24.7278 5.52797 25.4366C5.75476 25.6633 5.75476 26.0035 5.55632 26.2303C5.47127 26.3721 5.30118 26.4288 5.15944 26.4288Z"
        fill="inherit"
      />
      <path
        d="M15.1382 26.3721C12.8136 26.3721 10.4606 25.635 8.47621 24.1609C8.16438 23.9341 7.82419 23.6506 7.51236 23.3388C7.20052 23.0553 6.91703 22.7434 6.6619 22.4316C5.30116 20.8157 4.394 18.8597 4.11051 16.7902C3.96877 15.798 3.96877 14.8058 4.08216 13.7853C4.3373 11.6591 5.21611 9.67469 6.57685 8.03047C6.71859 7.86038 6.86034 7.69029 7.00208 7.54854C7.11547 7.43515 7.22887 7.32175 7.31391 7.23671C11.5662 3.01275 18.3699 2.92771 22.7639 7.03827C27.2714 11.2906 27.4982 18.3777 23.2742 22.8568C21.0914 25.1814 18.1148 26.3721 15.1382 26.3721ZM15.1382 5.16725C12.5868 5.16725 10.0637 6.13111 8.10768 8.08717C8.02263 8.17221 7.93759 8.25726 7.85254 8.3423C7.7108 8.48405 7.56905 8.62579 7.45566 8.79588C6.23666 10.27 5.4429 12.056 5.21611 13.9553C5.10272 14.8342 5.10272 15.7413 5.24446 16.6485C5.4996 18.5195 6.29336 20.2771 7.5407 21.7229C7.76749 22.0064 8.02263 22.2615 8.30612 22.545C8.58961 22.8285 8.87309 23.0553 9.18493 23.2821C13.2671 26.3437 18.9936 25.8334 22.4805 22.0914C26.2508 18.0659 26.0524 11.7158 22.0269 7.91707C20.0708 6.07441 17.6045 5.16725 15.1382 5.16725Z"
        fill="inherit"
      />
      <path
        d="M26.9313 28.1014C26.7895 28.1014 26.6478 28.0447 26.5344 27.9313L24.8618 26.3437C24.635 26.1169 24.635 25.7768 24.8335 25.55C25.0602 25.3232 25.4004 25.3232 25.6272 25.5216L27.2998 27.1092C27.5266 27.3359 27.5266 27.6761 27.3281 27.9029C27.2431 28.0447 27.073 28.1014 26.9313 28.1014Z"
        fill="inherit"
      />
      <path
        d="M36.4564 39.1006C35.9744 39.1006 35.5209 38.9305 35.1523 38.562L25.5138 28.9518C25.4004 28.8384 25.3437 28.6967 25.3437 28.5549C25.3437 28.4132 25.4004 28.2714 25.5138 28.158L29.0007 24.6428C29.2275 24.416 29.5676 24.416 29.7944 24.6428L39.433 34.253C40.1417 34.9617 40.1417 36.1524 39.433 36.8611L37.7321 38.562C37.3919 38.9305 36.9383 39.1006 36.4564 39.1006ZM26.7328 28.5549L35.9744 37.7683C36.2579 38.0517 36.7115 38.0517 36.9666 37.7683L38.6676 36.0673C38.9511 35.7838 38.9511 35.3303 38.6676 35.0751L29.4259 25.8618L26.7328 28.5549Z"
        fill="inherit"
      />
      <path
        d="M28.3487 26.5422C28.2069 26.5422 28.0652 26.4855 27.9801 26.4004L26.2225 24.7845C25.9957 24.5861 25.9674 24.2176 26.1942 23.9908C26.3926 23.764 26.7612 23.7356 26.9879 23.9624L28.7456 25.5783C28.9723 25.7767 29.0007 26.1453 28.7739 26.3721C28.6322 26.4855 28.4904 26.5422 28.3487 26.5422Z"
        fill="inherit"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_259">
        <rect
          width="40"
          height="38.1006"
          fill="white"
          transform="translate(0 1)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'SearchIcon',
}
</script>
